import { appSettings } from "../utils/appSettings";
import { dataEncrypter,dataDecrypter } from "../utils/crypter";
import invoke from 'react-native-webview-invoke/browser'
import { setAuthToken } from "../helpers/setAuthToken";
import axios from "axios";


export const userService = {
    login,
    logout,
    handleUserData,
    verifyToken,
};

const url = appSettings.server_url;


function login(username, password) {
    return axios.post(`${url}Authentication/authenticate`, { username, password })
        .then(res => {

            if (res.data.JwtToken) {
                setAuthToken(res.data.JwtToken);
                localStorage.setItem('_sm_gc22_ud', dataEncrypter(res.data)); //login data
                handleUserData(res.data.Id, 1);
            }
            return res.data;
        })
        .catch(err => {return err.response.data});
}

function handleUserData(FirmaPersonelId, isDeviceOrWeb) {
    return axios.post(`${url}User/UserDetail`, { FirmaPersonelId })
        .then(res => {
            if (res.data) {

                if(isDeviceOrWeb != 0) // webten geliyor ise 1 deviceden ise 0, webten gelirse localstoragede tutulacak datalar!
                {
                    localStorage.setItem('_sm_gc22_aud', dataEncrypter(res.data)); // user data
                }  
            }
            
            return res.data;

        })
        .catch(err => {return err.response.data});
}


function verifyToken(token) {
    const verifyHashData = dataDecrypter(token);
    const JwtToken = verifyHashData.JwtToken;
    const config = { headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + JwtToken, } };
    return axios.post(`${url}Authentication/verifyToken`, { JwtToken }, config)
        .then(res => {
            //eğer kullanıcı bulunursa (user.data.status = true) 
            if (res.data.JwtToken) {
                setAuthToken(res.data.JwtToken);
                handleUserData(res.data.Id, 0); // user datalarını tutmamak için ıd yanındaki değeri 0 göndererek localstorageyi deactive ediyoruz!
            }
            return verifyHashData;
        })
        .catch(err => {
            if (err != null) {
                logout();
            }
         
        })
}



function logout() {

    localStorage.removeItem('_sm_gc22_ud') // user bilgileri
    localStorage.removeItem('_sm_gc22_aud')
    setAuthToken(false);

    const handleLogout = invoke.bind('handleLogout') // react native tarafı !
   
    if (handleLogout) {
        handleLogout().then(() => { return "isLogout" })
    }


    // window.location.reload(false); // sayfayı refresh yaparak anasayfaya yönlendiriyor direk!
}



export default { login, logout, handleUserData, verifyToken };

