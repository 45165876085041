import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ROLES } from "../utils/appSettings.js";
import ProtectedRoutes from "./ProtectedRoutes.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const BlankLayout = lazy(() => import("../layouts/BlankLayout.js"));

/****Auth*****/
const Login = lazy(() => import("../views/auth/Login"));
/***** Pages ****/
const Home = lazy(() => import("../views/Home"));
const Profile = lazy(() => import("../views/Profile"));
const Talepler = lazy(() => import("../views/Talepler"));
const Error = lazy(() => import("../views/Error"));
const Unauthorized = lazy(() => import("../views/Unauthorized"));



/*****Routes******/

const Router = (isLoggedIn, role) => [
  {
    path: "/",
    element: isLoggedIn.isAuthenticated ? <FullLayout /> : <Navigate to="/login" />,
    children: [
      { path: "/", element: <Navigate to="/home" /> },
      { path: "/home", exact: true, element: <ProtectedRoutes currentUser={isLoggedIn} roleRequired={[ROLES.GuncelRole, ROLES.Administrator, ROLES.FirmaPublicRole, ROLES.FirmaAdminRole, ROLES.Muhasebe, ROLES.DestkMd]}><Home/></ProtectedRoutes> },
      { path: "/profile", exact: true, element: <ProtectedRoutes currentUser={isLoggedIn} roleRequired={[ROLES.GuncelRole, ROLES.Administrator, ROLES.FirmaPublicRole, ROLES.FirmaAdminRole, ROLES.Muhasebe, ROLES.DestkMd]}><Profile /> </ProtectedRoutes> },
      { path: "/talepler", exact: true, element: <ProtectedRoutes currentUser={isLoggedIn} roleRequired={[ROLES.GuncelRole, ROLES.Administrator, ROLES.FirmaPublicRole, ROLES.FirmaAdminRole, ROLES.Muhasebe, ROLES.DestkMd]}><Talepler /> </ProtectedRoutes> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '/401', element: <Navigate to="/401" /> },
    ],
  },
  {
    path: "/",
    element: !isLoggedIn.isAuthenticated ? <BlankLayout /> : <Navigate to="/" />,
    children: [ 
      { path: "/", element: <Navigate to="/login" /> },
      { path: "/login", exact: true, element: <Login /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '/401', element: <Navigate to="/401" /> },
    ],
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: "/401", element: <Unauthorized /> },
      { path: '/401', element: <Navigate to="/401" /> },

    ],
  },
];

export default Router;
