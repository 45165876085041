import React from "react"
import { Navigate, Outlet, Link, useLocation, Route, Routes } from "react-router-dom"
// import {useAuth} from "../utils/useAuth";


const ProtectedRoutes = ({ component: Component, getpath, getelement, currentUser, roleRequired, children, ...rest }) => {
	// let location = useLocation();
	// const { authUser, authUserData, auth, role } = useAuth()
	// alert(location.pathname)


	if (roleRequired) {
		return currentUser.isAuthenticated ? (
			// roleRequired.find(role => role.includes(role)) ? (
			roleRequired.includes(currentUser.user.RoleId) ? (
				children
				// <Outlet />
			) : (
				<Navigate to="/401" />
			)
		) : (
			<Navigate to="/login" />
		)
	} else {
		return currentUser.isAuthenticated ? /*<Outlet />*/ children : <Navigate to="/login"/>
	}
}


export default ProtectedRoutes
