import guncelUserImg from "../assets/images/users/guncel_user.png";
import defaultUserImg from "../assets/images/users/default_user.jpg";

const appSettings = {
  company: 'GÜNCEL YAZILIM',
  title: 'Destek Sistemi',
  versiyon: 'V1.0',
  GuncelYazilimAuthUserFirmaId: 1, // Güncel yazılım oturum açan userlerin firma id leri
  guncelUserAvatar: guncelUserImg,
  defaultUserAvatar: defaultUserImg,
  server_url: `https://smw.guncelyazilim.com.tr/api/`, // https://smw.guncelyazilim.com.tr/api/ http://localhost:5000/api/ http://localhost:32643/api/
  rss_url : `https://guncelyazilim.com.tr/feed/`,
  dateNowstringType: `${new Date().toLocaleString() + ""}`
};

const ROLES = {
  'FirmaPublicRole': "ff99b3f1-4845-4d3b-80c3-2b2bc562305c",
  'Administrator': "7de90876-c755-4845-8368-46a68e4434e6",
  'FirmaAdminRole': "aa399d41-dc12-4867-9f8d-7bf386dfe35b",
  'DestkMd': "387fe681-d8f6-4eb5-900b-7c3625e38c6f",
  'Muhasebe': "da5ebc63-7c18-4f3f-ab08-967beff69898",
  'GuncelRole': "7b838267-9086-40cd-8d2c-fe7891ebe312",
}

const navigation = [
  {
    title: "Talepler",
    href: "/talepler",
    icon: "bi bi-list-task",
  },
  {
    title: "Ana Sayfa",
    href: "/home",
    icon: "bi bi-house",
  },
  {
    title: "Profilim",
    href: "/profile",
    icon: "bi bi-person",
  },
];


export {
  appSettings,
  navigation,
  ROLES,
};

