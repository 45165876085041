import {decode as base64_decode, encode as base64_encode} from 'base-64';
const CryptoJS = require("crypto-js");


const dataEncrypter = (data) => {
    if (data != null) {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'yewk$prCndx7j@CGG7$6n@4ptbskE7EHacP*nS&DtZXEqGPWdsXXzag#%d3U').toString();
       
        const encoded = base64_encode(encryptedData); // şireli datayı base64 yapıyoruz ki parametre olarak iletildiğinde sorun çıkarmasın!
        return encoded;
    }
    else {
        return null;
    }

}

const dataDecrypter = (data) => {
    const decoded = base64_decode(data);
        // Decrypt
        const bytes = CryptoJS.AES.decrypt(decoded, 'yewk$prCndx7j@CGG7$6n@4ptbskE7EHacP*nS&DtZXEqGPWdsXXzag#%d3U');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        

        return decryptedData;
}

const localStoragedataDecrypter = (key) => {

    const lsdata = localStorage.getItem(`${key}`);
    const decoded = base64_decode(lsdata);

    if (lsdata != null) {
        const lsbytes = CryptoJS.AES.decrypt(decoded, 'yewk$prCndx7j@CGG7$6n@4ptbskE7EHacP*nS&DtZXEqGPWdsXXzag#%d3U');
        const lsdecryptedData = JSON.parse(lsbytes.toString(CryptoJS.enc.Utf8));
        return lsdecryptedData;
    }
    else {

        return null
    }
    // Decrypt



}

export {
    dataEncrypter,
    dataDecrypter,
    localStoragedataDecrypter
}