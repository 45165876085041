// import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import Router from "./routes/Router";
// import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.generic.light_custom_v1.css';
import 'font-awesome/css/font-awesome.min.css';

import { connect, useDispatch, useSelector } from 'react-redux';
import { dataDecrypter, dataEncrypter, localStoragedataDecrypter } from "./utils/crypter";


const App = () => {
  const dispatch = useDispatch();
  // const { authUser, authUserData, auth, role } = useAuth()
  const isLoggedIn = useSelector(state => state.auth); // anasayfada token gelince auth u buna göre yapıcak !


  const routing = useRoutes(Router(isLoggedIn));
  return <div className="dark">{routing}</div>;
};

export default App;
