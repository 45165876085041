import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from 'redux-thunk';
import { createLogger } from "redux-logger";

import rootReducer from './reducers/rootReducer';

// const loggerMiddleware = createLogger();

const logger = createLogger();
let middleware = [];
if (process.env.NODE_ENV === 'development') {
  console.log("Redux logger: development mode on, console log actives!")
  middleware = [...middleware, thunkMiddleware, logger];
} else {
  middleware = [...middleware, thunkMiddleware];
}



const store = createStore(rootReducer, applyMiddleware(...middleware));

export default store; 