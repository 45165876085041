// import { localStoragedataDecrypter } from "../utils/crypter";

// export const setAuthToken = (options) => {
//   const user = localStoragedataDecrypter('_sm_gc22_ud');

//   if (user && user.JwtToken) {
//     return 'Bearer ' + user.JwtToken;
//   }
//   else { return null }
// }


import axios from 'axios';

export const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        return token
    }
    else
        delete axios.defaults.headers.common["Authorization"];
        return null
}

