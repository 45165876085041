import authService from '../../services/authService';
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const VALIDATE_TOKEN_ERROR = "VALIDATE_TOKEN_ERROR";
export const LOGOUT = "LOGOUT";


const loginSuccess = (user, getUserData) => {
    return {
        type: LOGIN_SUCCESS,
        user,
        getUserData
    };
};



const loginError = error => {
    return {
        type: LOGIN_ERROR,
        error
    };
};

const validatetokenError = error => {
    return {
        type: VALIDATE_TOKEN_ERROR,
        error
    };
};

export const logout = () => {
    authService.logout();
    return {
        type: LOGOUT
    };
}

export const login = (username, password) => {
    return dispatch => {
        authService.login(username, password)
            .then(res => {
                res.message
                    ? dispatch(loginError(res.message))
                    : authService.handleUserData(res.Id)
                        .then(data => {
                            data.message
                                ? dispatch(loginError(data.message))
                                : (dispatch(loginSuccess(res, data)))
                        })
            })
            .catch(err => dispatch(loginError(err)));
    }
}


export const verifyToken = (token) => {
    return dispatch => {
        authService.verifyToken(token)
            .then(res => {
                res.message
                    ? dispatch(loginError(res.message))
                    : authService.handleUserData(res.Id)
                        .then(data => {
                            data.message
                                ? dispatch(loginError(data.message))
                                : (dispatch(loginSuccess(res, data)))
                        })
            })
            .catch(err => dispatch(validatetokenError()));
    }
}

